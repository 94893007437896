import { Component, Mixins, Emit } from 'vue-property-decorator';
import ViewPage from '@/mixins/Views';
import VueRouter from 'vue-router';
import store from '@/store';
import LayoutMapContainer from '@/components/layout/LayoutMapContainer.vue';
import PageHeader from '@/components/page/PageHeader.vue';
import axios, {AxiosResponse, AxiosError} from 'axios';

@Component({
  components: {
    PageHeader,
    LayoutMapContainer,
  },
})
export default class Map extends Mixins(ViewPage) {

  public hotelName: string = '';
  public hotelMapUrl: string = '';
  public hotelAddress: string = '';

  private name = 'hotel_map';
  private pageTitle1 = 'SEARCH';
  private pageTitle2 = 'FIND THE HOTEL';

  @Emit()
  private setDatas(data: HotelDetail) {
    this.hotelName = data.name;
    this.hotelMapUrl = data.map;
    this.hotelAddress = data.address;
  }
  @Emit()
  private async getHotel() {
    const lang = this.$store.getters['auth/lang'];
    const result = await axios({
      method: 'get',
      url: `/api/wp-json/31corp/v1/hotels?hotel=${this.$route.params.name}&lang=${lang}`,
      headers: { Authorization: 'Bearer ' + this.$store.getters['auth/token']},
    })
    .then( (response: AxiosResponse<ApiHotels>) => {
      this.setDatas(response.data.hotels[0]);
      return response;
    })
    .catch( (e: AxiosError) => {
      console.log(e);
      return false;
    });
    store.commit('loaded', true);
    return result;
  }

  private async mounted() {
    // const $container: any = this.$refs.container;
    await this.getHotel();
    store.commit('loaded', true);
  }
}
