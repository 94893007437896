
import { Component, Prop, Vue } from 'vue-property-decorator';
// @ is an alias to /src
import store from '@/store';

@Component
export default class PageSearchword extends Vue {
  private　name = 'pagesearchword';
  get text(): string {
    return this.$store.getters['search/word'];
  }
}
