import { Component, Prop, Emit, Vue } from 'vue-property-decorator';
import store from '@/store';
import NaviArchive from '@/components/navi/NaviArchive.vue';

// @ is an alias to /src

@Component({
  components: {
    NaviArchive,
  },
})
export default class SearchContainer extends Vue {
  private name = 'listarchives';

  get pageNum(): string {
    return this.$route.params.p;
  }

  get area(): string {
    return this.$route.params.area;
  }

  get list(): any {
    return this.$store.getters['search/data'];
  }

  get loaded(): boolean {
    return this.$store.getters['search/loaded'];
  }

  get isError(): boolean {
    return this.$store.getters['search/isError'];
  }

  get offset(): number {
    return store.getters['search/offset'];
  }

  get hits(): number {
    return Number(this.$store.getters['search/hits']);
  }

  get length(): number {
    return Number(this.$store.getters['search/length']);
  }

  get linkMaxButton(): number {
    return Math.ceil(Number(this.$store.getters['search/hits']) / Number(this.$store.getters['search/length']));
  }

}
