
import { Component, Mixins, Emit } from 'vue-property-decorator';
import SearchContainer from './';
import BoxArchivesHotel from '@/components/box/BoxArchivesHotel/BoxArchivesHotel.vue';
import BoxArchivesResort from '@/components/box/BoxArchivesResort/BoxArchivesResort.vue';
import axios, {AxiosResponse, AxiosError} from 'axios';

// @ is an alias to /src
const Words: Dictionary = {
  isError: 'データの取得に失敗しました。',
  noResult: `予約可能な施設が見つかりませんでした。<br>
            キーワードを変更するか、エリアを変更して検索してください。`,
  backToTop: 'トップへ戻る',
  backToTopLink: '/',
  hint02: '各プランの空室状況は予約画面にてご確認ください。',
  hint: 'チェックを入れるとトップページのお気に入りに登録されます。',
};
@Component({
  components: {
    BoxArchivesHotel,
    BoxArchivesResort,
  },
})
export default class SearchContainerJa extends Mixins(SearchContainer) {
  private words = Words;

  @Emit()
  private async getTodayPrice() {
    const token: string = this.$store.getters['auth/token'];
    const today: Date = new Date();
    const zero = (num: number): string => {
      const ret = ( '00' + num ).slice( -2 );
      return `${ret}`;
    };
    const params = {
      vacancy_date: `${today.getFullYear()}/${zero(today.getMonth() + 1)}/${zero(today.getDate())}`,
      vacancy_days: 1,
      man_count: 1,
      area: this.$route.params.area,
      get_days: 1,
      lang: 'ja',
    };

    const result = await axios({
      method: 'get',
      url: `/api/wp-json/31corp/v1/reservable`,
      params,
      headers: { Authorization: 'Bearer ' + token},
    });
    this.list.forEach((value: HotelDetail, index: number) => {
      const searchHotel = result.data.hotels.find((v: ReservableList) => {
        return v.detail.id === value.id;
      });
      if (typeof searchHotel !== 'undefined' && typeof searchHotel.schedule !== 'undefined' && searchHotel.schedule[0]) {
        this.$set(this.list[index], 'price', searchHotel.schedule[0].price);
      }
    });
  }

  private mounted() {
    this.getTodayPrice();
  }

  private updated() {
    this.getTodayPrice();
  }
}
