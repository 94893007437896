
import { Component, Mixins } from 'vue-property-decorator';
import BoxArchivesResort from './';
const Words: Dictionary = {
  address: '住所',
  map: 'アクセス',
  btOfficialSite: '公式ホームページ',
};
@Component
export default class BoxArchivesResortJa extends Mixins(BoxArchivesResort) {
  private words = Words;
}
