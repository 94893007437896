
import { Component, Mixins } from 'vue-property-decorator';
import SearchContainer from './';
import BoxArchivesHotel from '@/components/box/BoxArchivesHotel/BoxArchivesHotelEn.vue';
import BoxArchivesResort from '@/components/box/BoxArchivesResort/BoxArchivesResortEn.vue';
// @ is an alias to /src
const Words: Dictionary = {
  isError: 'Acquisition of data failed.',
  noResult: `No facilities available were found. Please change the keyword or destination.`,
  backToTop: 'Back to top',
  backToTopLink: '/',
  hint02: 'Please check the availability of each plan on the reservation screen.',
  hint: 'If you check the <span class="icon"></span> button, it will be registered as your favorite on the top page.',
};
@Component({
  components: {
    BoxArchivesHotel,
    BoxArchivesResort,
  },
})
export default class SearchContainerJa extends Mixins(SearchContainer) {
  private words = Words;
}
