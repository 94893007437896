
import { Component, Mixins } from 'vue-property-decorator';
import MapPage from '@/mixins/Map';
// @ is an alias to /src

const Words: Dictionary = {
  companyName: '様',
  label: 'の地図',
  address: '住所',
  btClose: '閉じる',
};

@Component
export default class Map extends Mixins(MapPage) {
  private words = Words;

  get userName() {
    return this.$store.getters['auth/name'];
  }
  get companyName(): string {
    return `${this.userName}${this.words.companyName}`;
  }
  get label(): string {
    return `${this.hotelName} ${this.words.label}`;
  }
  get address(): string {
    return `${this.words.address} : ${this.hotelAddress}`;
  }
}
