import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import store from '@/store';
import ButtonFavoriteHeart from '@/components/button/ButtonFavoriteHeart.vue';
import HistoryDirective from '@/directives/history';
import GaSendSite from '@/directives/GaSendSite';
import ButtonMovie from '@/components/button/ButtonMovie.vue';
// @ is an alias to /src

@Component({
  components: {
    ButtonFavoriteHeart,
    ButtonMovie,
  },
  directives: {
    history: HistoryDirective,
    gasendsite: GaSendSite,
  },
})
export default class BoxArchivesResort extends Vue {
  private　name = 'boxarchivesresort';
  @Prop()
  private datas!: HotelDetail;
  get isPremium() {
    let result = true;
    if (this.datas.category.indexOf( 'luxury' ) === -1) {
      result = false;
    }
    return result;
  }
}
