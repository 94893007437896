
import { Component, Mixins } from 'vue-property-decorator';
import BoxHotel from '@/mixins/BoxHotel';
const Words: Dictionary = {
  address: 'Address',
  access: 'Station',
  btOfficialSite: 'OFFICIAL SITE',
  btReserve: 'BOOK NOW',
};
@Component
export default class BoxArchivesHotelEn extends Mixins(BoxHotel) {
  private words = Words;
}
