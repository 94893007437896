
import { Component, Mixins } from 'vue-property-decorator';
import BoxArchivesResort from './';
const Words: Dictionary = {
  address: 'Address',
  map: 'ACCESS',
  btOfficialSite: 'OFFICIAL SITE',
};
@Component
export default class BoxArchivesResortJa extends Mixins(BoxArchivesResort) {
  private words = Words;
}
