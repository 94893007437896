
import { Component, Mixins } from 'vue-property-decorator';
import BoxHotel from '@/mixins/BoxHotel';
const Words: Dictionary = {
  address: '住所',
  access: '交通',
  btOfficialSite: '公式ホームページ',
  btReserve: '予約する（法人優待）',
};
@Component
export default class BoxArchivesHotelJa extends Mixins(BoxHotel) {
  private words = Words;
}
