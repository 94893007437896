import { Component, Vue, Emit, Provide } from 'vue-property-decorator';
import ViewPage from '@/mixins/Views';
import VueRouter from 'vue-router';
// @ is an alias to /src
import store from '@/store';
import PageHeader from '@/components/page/PageHeader.vue';
import PageSearchword from '@/components/page/PageSearchword.vue';
import {AxiosResponse} from 'axios';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
  'beforeRouteLeave',
]);

@Component({
  components: {
    PageHeader,
    PageSearchword,
  },
})
export default class Search extends Vue {
  private name = 'search';
  get sideNaviDate(): boolean {
    if (
      this.$route.params.area === 'taiwan' ||
      this.$route.params.area === 'shizuoka'
    ) {
      return false;
    } else {
      return true;
    }
  }
  @Emit()
  private hotelNoResult() {
    if (store.getters['search/hits'] === 0) {
      window.gtag('event', 'hotelSeach', { searchNoResult: this.$route.params.keyword });
    }
  }
  private async beforeRouteEnter(to: any, from: VueRouter, next: any) {
    if (store.getters['search/isError'] === 0) {
      store.commit('search/resetData');
      const result: AxiosResponse<ApiHotels> = await store.dispatch('search/getEntry', {
        name: to.name,
        keyword: to.params.keyword,
        area: to.params.area,
        hotel: to.params.name,
        offset: to.params.p,
        brand: to.meta.brand,
        length: 10,
      });
      let breadcrumbPath: BreadcrumbPath[] = [];
      if (store.getters['auth/lang'] === 'en') {
        breadcrumbPath = [
          {
            label: 'home',
            url: '/en',
          },
          {
            label: store.getters['search/word'],
            url: to.path,
          },
        ];
      } else {
        breadcrumbPath = [
          {
            label: 'ホーム',
            url: '/',
          },
          {
            label: store.getters['search/word'],
            url: to.path,
          },
        ];
      }

      store.commit('breadcrumb/setPath', breadcrumbPath);
      store.commit('loaded', true);
      if (result.status === 200) {
        next((vm: any) => {
          if (result.data.hits === 0) {
            vm.hotelNoResult();
          }
        });
      } else {
        next();
      }
    } else {
      store.commit('search/setLoaded', true);
      store.commit('loaded', true);
      next();
    }
  }
  private async beforeRouteUpdate(to: any, from: VueRouter, next: any) {
    if (store.getters['search/isError'] === 0) {
      store.commit('search/resetData');
      const result: AxiosResponse<ApiHotels> = await store.dispatch('search/getEntry', {
        name: to.name,
        keyword: to.params.keyword,
        area: to.params.area,
        hotel: to.params.name,
        offset: to.params.p,
        brand: to.meta.brand,
        length: 10,
      });
      let breadcrumbPath: BreadcrumbPath[] = [];
      if (store.getters['auth/lang'] === 'en') {
        breadcrumbPath = [
          {
            label: 'home',
            url: '/en',
          },
          {
            label: store.getters['search/word'],
            url: to.path,
          },
        ];
      } else {
        breadcrumbPath = [
          {
            label: 'ホーム',
            url: '/',
          },
          {
            label: store.getters['search/word'],
            url: to.path,
          },
        ];
      }
      store.commit('breadcrumb/setPath', breadcrumbPath);
      store.commit('loaded', true);
      next();
      if (result.data.hits === 0) {
        this.hotelNoResult();
      }
    } else {
      store.commit('search/setLoaded', true);
      store.commit('loaded', true);
      next(); // needs to be called to confirm the navigation
    }
  }
}
