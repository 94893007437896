
import { Component, Mixins } from 'vue-property-decorator';
import MapPage from '@/mixins/Map';

const Words: Dictionary = {
  companyName: '',
  label: 'Map of ',
  address: 'Address',
  btClose: 'CLOSE',
};

@Component
export default class Map extends Mixins(MapPage) {
  private words = Words;
  get userName() {
    return this.$store.getters['auth/nameEn'];
  }
  get companyName(): string {
    return `${this.userName}${this.words.companyName}`;
  }
  get label(): string {
    return `${this.words.label}${this.hotelName}`;
  }
  get address(): string {
    return `${this.words.address} : ${this.hotelAddress}`;
  }
}
