
import { Component, Mixins, Emit } from "vue-property-decorator";
import Search from "@/mixins/Search";
import ViewsHasSidenavi from "@/mixins/ViewsHasSidenaviEn";
import store from "@/store";
import SearchContainer from "@/components/search/SearchContainer/SearchContainerEn.vue";
@Component({
  components: {
    SearchContainer,
  },
})
export default class SearchKeyword extends Mixins(Search, ViewsHasSidenavi) {}
