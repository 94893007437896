
import { Component, Prop, Emit, Watch, Vue } from 'vue-property-decorator';
import VueRouter from 'vue-router';
import store from '@/store';

interface NaviArchiveProps {
  offset: number;
  length: number;
  total: number;
  paramsOrQuery: string;
  numKey: string;
}

@Component
export default class NaviArchive extends Vue {
  private name = 'naviarchive';
  @Prop()
  private data!: NaviArchiveProps;

  get linkMaxButton(): number {
    return Math.ceil(Number(this.data.total) / Number(this.data.length));
  }

  // get offset(): number {
  //   return store.getters['search/offset'];
  // }

  // get linkMaxButton(): number {
  //   return Math.ceil(Number(this.$store.getters['search/hits']) / Number(this.$store.getters['search/length']));
  // }

  get prevButton(): boolean {
    if (this.linkMaxButton > 1 && this.data.offset !== 1) {
      return false;
      } else {
      return true;
    }
  }

  get nextButton(): boolean {
    if (this.data.offset < this.linkMaxButton) {
      return false;
      } else {
      return true;
    }
  }

  @Emit()
  private isCurrent(n: number): boolean {
    if (n === this.data.offset) {
      return true;
    } else {
      return false;
    }
  }

  @Emit()
  private toPrev(): any {
    if (this.data.offset <= 1) {
      return this.$route.fullPath;
    } else {
      // return this.data.path + '/' + String(this.data.offset - 1);
      const result: any = {};
      result[this.data.paramsOrQuery] = {};
      result[this.data.paramsOrQuery][this.data.numKey] = this.data.offset - 1;
      return result;
      // return { query: {p: this.data.offset - 1 } };
    }
  }

  @Emit()
  private toNext(): any {
    if (this.data.offset * this.data.length >= this.data.total) {
      return this.$route.fullPath;
    } else {
      // return this.path + '/' + String(this.offset + 1);
      const result: any = {};
      result[this.data.paramsOrQuery] = {};
      result[this.data.paramsOrQuery][this.data.numKey] = this.data.offset + 1;
      return result;
      // return { query: {p: this.data.offset + 1 } };
    }
  }

  @Emit()
  private toLinks(n: number): any {
    // return this.path + '/' + n;
    // return { path: this.data.path, params: {p: `${n}` } };
    // return { query: { p: `${n}`} };
    const result: any = {};
    result[this.data.paramsOrQuery] = {};
    result[this.data.paramsOrQuery][this.data.numKey] = n;
    return result;
    // return { params: { p: `${n}`} };
  }

}
